import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type GalleryItem = {
  img?: ImageDataLike
  imgLink?: string
  alt?: string
  link: string
  badge1Text: string
  badge2Text: string
}

type Props = {
  heading: string
  gallery: GalleryItem[]
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px 0 60px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 768px;
  margin-inline: auto;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;

    overflow: hidden;
    max-width: unset;
    border-radius: 20px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    gap: 15px;
    border-radius: 40px;
  }
`

const TileSingleWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;

  ${({ theme }) => theme.media.lg.min} {
    height: 180px;
    margin-bottom: 0;
    border-radius: 0;

    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    :nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    :nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }

    :nth-child(4) {
      grid-area: 2 / 1 / 3 / 4;
      height: 200px;
      img {
        object-position: 50% 12.5%;
      }
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    height: 220px;

    :nth-child(4) {
      height: 260px;
    }
  }

  ${({ theme }) => theme.media.xxxl.min} {
    height: 300px;

    :nth-child(4) {
      height: 340px;
    }
  }
`

const TileLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const BadgeTopWrapper = styled.div`
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.primary100};

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    padding: 8px 24px 8px 24px;
    border-bottom-right-radius: 5px;
    z-index: 1;
  }
`

const BadgeWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
  max-width: 65%;
  padding: 20px 20px 20px 15px;
  background-color: #0000007a;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 1;

  ${({ theme }) => theme.media.xxxl.min} {
    ${Text} {
      font-size: 18px;
    }
  }
`

const ImgWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    height: 100%;

    //Gatsby img wrapper
    & > div {
      height: 100%;
      width: 100%;
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }
`

const PostsTiles1: React.FC<Props> = ({ heading, gallery }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <Heading
          as="h2"
          size={28}
          weight={600}
          align={lg ? 'left' : 'center'}
          themecolor="black"
          margin="20px"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <TilesWrapper>
          {gallery.map(
            ({ img, imgLink, link, alt, badge1Text, badge2Text }) => (
              <TileSingleWrapper key={badge1Text}>
                <TileLink href={link} />
                <BadgeTopWrapper>
                  <Text
                    size={lg ? 14 : 20}
                    weight={500}
                    themecolor="white"
                    margin="0"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: badge1Text }}
                  />
                </BadgeTopWrapper>
                {badge2Text && (
                  <BadgeWrapper>
                    <Text
                      size={14}
                      weight={600}
                      themecolor="white"
                      margin="0"
                      align="left"
                      dangerouslySetInnerHTML={{ __html: badge2Text }}
                    />
                  </BadgeWrapper>
                )}
                <ImgWrapper>
                  {img ? (
                    <LazyImage src={img} alt={alt || ''} />
                  ) : (
                    <img src={imgLink} alt={alt || ''} />
                  )}
                </ImgWrapper>
              </TileSingleWrapper>
            )
          )}
        </TilesWrapper>
      </Container>
    </Section>
  )
}

export default PostsTiles1
