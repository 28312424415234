import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Links from 'components/layout/Sections/Pages/Wiadomosci/Links'
import Tiles1 from 'components/layout/Posts/PostsTiles1'

import Navigation from 'components/layout/Navigation/index'

import { removeWhitespaces } from 'utils/removeWhitespaces'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inspiracje',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inspiracje',
    },
  ],
}

const links = [
  {
    label: 'Realizacje',
    link: '/inspiracje/realizacje/',
  },
  {
    label: 'Produkty',
    link: '/inspiracje/produkty/',
  },
]

const InspiracjePage: React.FC<PageProps> = ({ data }) => {
  const PAGE_SEO = data?.wpPage?.seo

  const GALLERY = React.useMemo(
    () => [
      {
        img: data?.realisations?.realizacje?.realisationSingle[0]
          .realisationSingleImg[0].realisationSingleImgSingle?.localFile
          ?.childImageSharp?.gatsbyImageData,
        alt: 'realisation',
        badge1Text: 'Realizacja',
        badge2Text:
          data?.realisations?.realizacje?.realisationSingle[0]
            ?.realisationSingleTileTitle,
        link: `/inspiracje/realizacje/#${removeWhitespaces(
          data?.realisations?.realizacje?.realisationSingle[0]?.realisationSingleTileTitle.toLowerCase()
        )}/`,
      },
      {
        img: data?.products?.produkty?.productsSingle[0].productsSingleImg1
          ?.localFile?.childImageSharp?.gatsbyImageData,
        alt: 'products',
        badge1Text: 'Produkt',
        badge2Text:
          data?.products?.produkty?.productsSingle[0].productsSingleTitle1,
        link: `/inspiracje/produkty/#${removeWhitespaces(
          data?.products?.produkty?.productsSingle[0]?.productsSingleTitle1?.toLowerCase()
        )}/`,
      },
      {
        img: data?.realisations?.realizacje?.realisationSingle[1]
          .realisationSingleImg[1].realisationSingleImgSingle?.localFile
          ?.childImageSharp?.gatsbyImageData,
        alt: 'realisation',
        badge1Text: 'Realizacja',
        badge2Text:
          data?.realisations?.realizacje?.realisationSingle[1]
            ?.realisationSingleTileTitle,
        link: `/inspiracje/realizacje/#${removeWhitespaces(
          data?.realisations?.realizacje?.realisationSingle[1]?.realisationSingleTileTitle.toLowerCase()
        )}/`,
      },
      {
        img: data?.products?.produkty?.productsSingle[0].productsSingleImg2
          ?.localFile?.childImageSharp?.gatsbyImageData,
        alt: 'products',
        badge1Text: 'Produkt',
        badge2Text:
          data?.products?.produkty?.productsSingle[0].productsSingleTitle2,
        link: `/inspiracje/produkty/#${removeWhitespaces(
          data?.products?.produkty?.productsSingle[0]?.productsSingleTitle2?.toLowerCase()
        )}/`,
      },
    ],
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          data?.wpPage?.inspiracje?.inspirationBannerImg?.localFile
            ?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          data?.wpPage?.inspiracje?.inspirationBannerImg?.localFile
            ?.childImageSharp?.gatsbyImageData
        }
        headingMobile="Inspiracje"
        headingDesktop="Inspiracje"
        breadcrumbs={breadcrumbs}
      />
      <Links links={links} alignLeft />
      <Tiles1 heading="Inspiracje" gallery={GALLERY} />
    </Layout>
  )
}

export default InspiracjePage

export const query = graphql`
  query InspiracjePage {
    wpPage(slug: { eq: "inspiracje" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      inspiracje {
        inspirationBannerTitle
        inspirationBannerImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    products: wpPage(slug: { eq: "produkty" }) {
      produkty {
        productsSingle {
          productsSingleTitle1
          productsSingleTitle2
          productsSingleImg1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
          productsSingleImg2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
        }
      }
    }
    realisations: wpPage(slug: { eq: "realizacje" }) {
      realizacje {
        realisationSingle {
          realisationSingleTitle
          realisationSingleTileTitle
          realisationSingleImg {
            realisationSingleImgSingle {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
