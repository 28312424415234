import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import useBreakpoint from 'hooks/useBreakpoint'

const Section = styled.section`
  width: 100%;
  padding: 15px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

type Link = {
  label: string
  link: string
}

type Props = {
  links: Link[]
  alignLeft?: boolean
}

const LinksWrapper = styled.div<{ alignLeft?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.lg.min} {
    padding-inline: 75px;
    flex-wrap: nowrap;
    justify-content: space-around;

    ${({ alignLeft }) =>
      alignLeft &&
      css`
        justify-content: flex-start;
        padding-left: 0;
      `}
  }
`

const LinkItem = styled.a`
  padding: 8px 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.primary};

  transition: 0.3s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary200};
  }

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.primary300};
    border-radius: 5px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 8px 25px;
  }
`

const Links: React.FC<Props> = ({ links, alignLeft }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <LinksWrapper alignLeft={alignLeft}>
          {links.map(({ label, link }) => (
            <LinkItem key={label} href={link}>
              <Text
                size={lg ? 18 : 16}
                weight={500}
                themecolor="white"
                margin="0"
                align="center"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </LinkItem>
          ))}
        </LinksWrapper>
      </Container>
    </Section>
  )
}

export default Links
